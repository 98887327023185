// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-drinks-jsx": () => import("./../../../src/pages/drinks.jsx" /* webpackChunkName: "component---src-pages-drinks-jsx" */),
  "component---src-pages-gift-certificates-jsx": () => import("./../../../src/pages/gift-certificates.jsx" /* webpackChunkName: "component---src-pages-gift-certificates-jsx" */),
  "component---src-pages-happy-hour-specials-jsx": () => import("./../../../src/pages/happy-hour-specials.jsx" /* webpackChunkName: "component---src-pages-happy-hour-specials-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */)
}

